/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { createContext, useReducer } from 'react';
import { IL10N } from '../../Common/l10n-keys';
import { ArticleTileContextActionType } from '../../Common/enums';

export const ArticleTileContext = createContext({
    l10n: {} as IL10N,
    mspOrigin: '',
    setMspOrigin: (origin: string) => {},
});

function catStateReducer(state, action) {
    if (action.type === ArticleTileContextActionType.SetMspOrigin){
        return {
            mspOrigin: action.payload.mspOrigin,
        };
    }

    return {
        mspOrigin: '',
    };
}

export default function ArticleTileContextProvider(
    props: React.PropsWithChildren<{l10n: IL10N}>) {
    const [catState, catStateDispatch ] = useReducer(catStateReducer, {mspOrigin: ''});

    function setMspOrigin(origin: string) {
        catStateDispatch({type: ArticleTileContextActionType.SetMspOrigin, payload: {mspOrigin: origin}});
    }

    const ctxValue = {
        l10n: props.l10n,
        setMspOrigin: setMspOrigin,
        mspOrigin: catState.mspOrigin
    };

    return (
        <ArticleTileContext.Provider value={ctxValue}>
            {props.children}
        </ArticleTileContext.Provider>
    );
}
