import React, { Component } from 'react';
import styles from './bookmark.scss';
import { ISdvColor } from '../article-tile.d';
import { ViewType } from '../../../../Common/enums';
import TabNavigationHelper from '../../../../Common/tabNavigationHelper';

export default class CompareBookmark extends Component<{
    id: string, variant: ISdvColor,
    viewType: ViewType, gridNumber: number,
    categoryName: string, bookmarkMlt: string
}, {}> {
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;
    }

    componentDidMount(): void {
        if (typeof window !== 'undefined' && window.shell) {
            // publish msg to bookmark-add-button
            window.shell.publishTo('ESCID.ESPP.Bookmark.CreateClientSideAddBtn', {
                containerId: this.getId(false),
                isMobile: false,
                isRedesignOrderModal: true,
                localization: { bookmark: this.props.bookmarkMlt }
            });
        }
    }

    private handleKeyDown(event): void {
        // prevent events on tile core:
        event.stopPropagation();   
    }

    public render() {
        return (
            <div id={this.getId(true)}
                data-testid='bookmark_button'
                data-color={this.props.variant.color.name}
                data-categoryname={this.props.categoryName}
                data-savkey={this.props.variant.salesArticleVariantKey}
                ref={(el) => this.tabNav.setGroupAtt(el)}
                className={styles.bookmark_button}>
                <div id={this.getId(false)} data-containerid={this.getId(false)}
                onKeyDown={this.handleKeyDown}/>
            </div>
        );
    }

    private getId(isWrapper: boolean): string {
        let id = isWrapper ? `ats_${this.props.id}` : this.props.id;
        if (this.props.viewType === ViewType.TeaserSuggestion) id += `_${this.props.gridNumber}`;
        return id;
    }
}
