import React, { Component, RefObject } from 'react';
import styles from './eyeCatcher.scss';
import classNames from 'classnames';
import { ArticleTileContext } from '../../../globalState/articleTileContext';

interface IEyeCatcherProps
{
    isNew: boolean,
    hasUvp: boolean,
    setPrice: boolean,
    sale: boolean,
    priceDeduction: number,
    availableSizesOpen: boolean
    flagAdvertisedAsCostFreeInSet: boolean;
    numberOfColorsForAvailableSizes: number;
    eyeCatcherRef?: RefObject<HTMLDivElement>;
}

export default class EyeCatcher extends Component<IEyeCatcherProps,{}> {
    static contextType = ArticleTileContext;

    constructor(props) {
        super(props);
    }

    private get hasPriceDeduction(): boolean {
        return this.props.priceDeduction && this.props.priceDeduction != 0;
    }

    private get baseStyles(): { [key: string]: boolean } {
        const baseStyles = {};
        baseStyles[styles.t_eyeCatcher] = true;
        
        if (!this.props.availableSizesOpen) 
            return baseStyles;

        return baseStyles;
    }

    public render() {
        const baseStyles = this.baseStyles;
        const { l10n } = this.context;
        return (
            <div className={styles.eyeCatcher_wrapper}>
                {this.props.isNew &&
                    <div ref={this.props.eyeCatcherRef ? this.props.eyeCatcherRef : null} 
                        className={classNames(baseStyles, styles.is_new)} data-testid='flagIsNew'>
                        <span className={styles.inner_text}>{l10n.isNew}</span>
                    </div>}
                {this.props.hasUvp && this.hasPriceDeduction &&
                    <div ref={this.props.eyeCatcherRef ? this.props.eyeCatcherRef : null} 
                        className={classNames(baseStyles, styles.uvp)} data-testid='flagHasUvp'>
                        <span className={styles.inner_text} data-testid='priceDeduction_under_flagHasUvp'>
                            {`${l10n.uvp} ${this.props.priceDeduction}%`}
                        </span>
                    </div>}
                {this.props.setPrice && this.hasPriceDeduction && !this.props.flagAdvertisedAsCostFreeInSet &&
                    <div ref={this.props.eyeCatcherRef ? this.props.eyeCatcherRef : null}
                        className={classNames(baseStyles, styles.set_price)} data-testid='flagSetPrice'>
                        <span className={styles.inner_text} data-testid='priceDeduction_under_flagSetPrice'>
                            {`${l10n.setPrice} ${this.props.priceDeduction}%`}
                        </span>
                    </div>}
                {this.props.flagAdvertisedAsCostFreeInSet &&
                    <div ref={this.props.eyeCatcherRef ? this.props.eyeCatcherRef : null} 
                        className={classNames(baseStyles, styles.set_price)} 
                        data-testid='flagAdvertisedAsCostFreeInSet'>
                        <span className={styles.inner_text}>
                            {l10n.sale}
                        </span>
                    </div>}
                {this.props.sale && !this.props.setPrice
                 && this.hasPriceDeduction && !this.props.flagAdvertisedAsCostFreeInSet &&
                    <div ref={this.props.eyeCatcherRef ? this.props.eyeCatcherRef : null} 
                        className={classNames(baseStyles, styles.sale)} data-testid='flagSale'>
                        <span className={styles.inner_text} data-testid='priceDeduction_under_flagSale'>
                            {`${l10n.sale} ${this.props.priceDeduction}%`}
                        </span>
                    </div>
                }
            </div>
        );
    }
}
