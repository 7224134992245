import * as React from 'react';
import FilterContent from '../filterContent/filterContent';
import styles from './filterDropDown.scss';
import ArrowDownIcn from '../../Assets/svg/arrow_down';
import { IFilterDropDownProp, IFilterDropDownState } from './filterDropDownProp';
import classNames from 'classnames';
import AvailabilityFilter from '../availabilityFilter/availabilityFilter';
import SpinnerComponent from '../spinnerComponent/spinnerComponent';
import TooltipComponent from '../tooltipComponent/tooltipComponent';
import { ScrollLock } from '../../../Helper/scrollLock';
import { isIPad13, isTablet } from 'react-device-detect';
import { SortContext } from '../globalState/sortContextProvider';
import { ISortContext } from '../globalState/sortContextProvider.d';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class FilterDropDown extends React.Component<IFilterDropDownProp,
    IFilterDropDownState> {
    static contextType = SortContext;
    private dropDownRef = React.createRef<HTMLDivElement>();
    private dropDownContentRef = React.createRef<HTMLDivElement>();
    private scrollLock: ScrollLock;
    private mouseYPosition: number;
    private tabNav: TabNavigationHelper;

    constructor(props: IFilterDropDownProp, context) {
        super(props, context);
        this.state = {
            dropDownMinHeightStyle: {
                height: 'auto',
                minHeight: 0,
            },
            selectedTooltipText: null,
            showTooltip: false,
            tooltipImageName: null,
        };

        this.tabNav = TabNavigationHelper.instance;

        this.scrollLock = ScrollLock.instance;
        this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateTooltip = this.updateTooltip.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);

        if(this.dropDownContentRef.current) {
            this.tabNav.setGroupAtt(this.dropDownContentRef.current);
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    public componentDidUpdate() {
        // for screen auto scroll, tablet only:
        if (this.dropDownContentRef.current && (isTablet || isIPad13)) {
            const dropDownContentHeightBottom: number =
                this.dropDownContentRef.current.clientHeight + this.mouseYPosition + 30;

            if (window.innerHeight < dropDownContentHeightBottom) {
                const goUp = dropDownContentHeightBottom - window.innerHeight;
                window.scrollBy({
                    behavior: 'smooth',
                    top: goUp,
                });
            }
        }
    }

    private handleKeyDown(event): void {
        // prevent page jump down on Space btn., click
        if (event.key === ' ') {//'Space'
            event.preventDefault();
            this.toggleFilterDropdown();
        }

        if (event.key === 'Enter') {
            this.toggleFilterDropdown();
        }
    }

    public render() {
        const sortContext: ISortContext = this.context;
        let filterCount: number;
        if (this.props.filterProperty.name === 'GlovesRecommendedUses') {
            filterCount = sortContext.activeGlovesSortPropValues.length
        } else {
            filterCount = this.props.selectedFilters.filter((filter =>
                filter.dimension.name.indexOf(this.props.filterProperty.name) > -1)).length;
        }
        const dropDownClasses = classNames(styles.dropdown_close,
            this.props.filterProperty.name === this.props.activeDropDownFilterName ?
                styles.dropdown_open : '',
            filterCount > 0 ? styles.dropdown_active : '');

        return (
            <div ref={this.dropDownRef} className={classNames(styles.filter_dropdown,
                { [styles.disabled_dropdown]: this.props.filterProperty.allDisabled })}
                onKeyDown={this.handleKeyDown}>
                <div className={dropDownClasses}
                    ref={(el)=> this.tabNav.setFocusAtt(el)}
                    data-testid='filterDropdown'
                    onClick={this.toggleFilterDropdown}>
                    {filterCount > 0 && (
                        <div className={styles.filter_count_container}>
                            <div className={styles.filter_count}>{filterCount}</div>
                        </div>
                    )}
                    <div className={styles.filter_property_title} data-name={this.props.filterProperty.name}>
                        {this.props.filterProperty.title}
                    </div>

                    <ArrowDownIcn />
                </div>
                {
                    this.props.filterProperty.name === this.props.activeDropDownFilterName &&
                    (
                        this.dropDownContent()
                    )
                }
            </div>
        );
    }

    public updateTooltip(visibility: boolean, text: string, imageName?: string) {
        this.setState({
            selectedTooltipText: text,
            showTooltip: visibility,
            tooltipImageName: imageName,
        }, this.adjustDropDownMinHeight);
    }

    private adjustDropDownMinHeight() {
        if (this.state.showTooltip) {
            const dropDownContent: Element =
                this.dropDownRef.current.querySelector('.fas_dropdown_content');
            const tooltipCloseButton: Element =
                this.dropDownRef.current.querySelector('.fas_tooltip_overlay .fas_close_button');

            if (!dropDownContent || !tooltipCloseButton)
                return;

            const requiredMarginTop = 14;
            const dropdownOffset: number = dropDownContent.getBoundingClientRect().top;
            const closeButtonOffset: number = tooltipCloseButton.getBoundingClientRect().top;
            const missingSpace: number = dropdownOffset + requiredMarginTop - closeButtonOffset;

            if (missingSpace > 0)
                this.setState({
                    dropDownMinHeightStyle: {
                        height: 0,
                        minHeight: (dropDownContent.scrollHeight + 2 * missingSpace),
                    },
                });

        } else
            this.setState({
                dropDownMinHeightStyle: {
                    height: 'auto',
                    minHeight: 0,
                },
            });
    }

    private handleClickOutside(event: Event) {
        if (this.props.filterProperty.name !== this.props.activeDropDownFilterName)
            return;

        let eventObject = event.target as Element;
        while (eventObject.className.length <= 0 || !(eventObject instanceof HTMLElement)) {
            if (!eventObject.parentElement)
                break;
            eventObject = eventObject.parentElement;
        }

        let isContainEventObject = false;
        let isDropDownButton = false;
        let isDropDownTitle = false;

        if (eventObject) {
            isContainEventObject = this.dropDownContentRef.current.contains(eventObject);
            isDropDownButton = eventObject.classList.contains('fas_dropdown_close');
            isDropDownTitle = eventObject.classList.contains('fas_filter_property_title');
        }

        if (this.dropDownRef.current)
            if (!isContainEventObject && !isDropDownButton && !isDropDownTitle)
                this.toggleFilterDropdown();
    }

    private dropDownContent() {
        return (
            <div ref={this.dropDownContentRef}
                className={classNames(styles.dropdown_content, this.state.showTooltip ? 'fas_tooltip_opened' : '')}
                style={this.state.dropDownMinHeightStyle}
                onKeyDown={this.handleKeyDown}
            >
                <SpinnerComponent context={'fas-bar-dropdown-' + this.props.filterProperty.name}
                    isFixedLayout={false}
                    overlayBreadcrumb={false}
                    globalState={this.props.globalState} />
                <TooltipComponent showTooltip={this.state.showTooltip}
                    text={this.state.selectedTooltipText}
                    imageName={this.state.tooltipImageName}
                    updateTooltip={this.updateTooltip} />
                <FilterContent
                    dimension={this.props.filterProperty}
                    addSelectedFilter={this.props.addSelectedFilter}
                    selectedFilters={this.props.selectedFilters}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    updateTooltip={this.updateTooltip}
                    l10n={this.props.l10n}
                    isMobile={this.props.isMobile}
                />
                <AvailabilityFilter
                    isAvailable={this.props.isAvailable}
                    setIsAvailableFilter={this.props.setIsAvailableFilter}
                    mltTitle={this.props.l10n.readyForImmediateShipment}
                    mltTitleSmall={this.props.l10n.readyForImmediateShipment}
                    tooltipText={this.props.l10n.deliveryTime}
                    updateTooltip={this.updateTooltip} />
                <div className={styles.results_counter} ref={(el)=> this.tabNav.setFocusAtt(el)}>
                    {this.getmatchingArticleCounter()}
                </div>
            </div>
        );
    }

    private getmatchingArticleCounter() {
        const result: string = this.props.articleCount > 1 ?
            this.props.l10n.showItems :
            this.props.l10n.showItem;

        const strings = result.split('{0}');
        let surfix = '';
        let prefix = '';
        if (strings.length > 1) {
            prefix = strings[0];
            surfix = strings[1];
        } else
            surfix = strings[0];

        return (
            <div className={styles.results_counter_text}
                data-testid='results_counter_text'
                onClick={this.toggleFilterDropdown}>
                {prefix} <b>{this.props.articleCount}</b> {surfix}
            </div>
        );
    }

    private toggleFilterDropdown(event: React.MouseEvent<HTMLElement, MouseEvent> = null) {
        if(window.shell && window.shell.tabNav) // when modal is destroyed, tanNav loses focus, need to give him a hint
            window.shell.tabNav.focus(this.dropDownRef.current);

        // for screen auto scroll, tablet only:
        if (event && (isTablet || isIPad13))
            this.mouseYPosition = event.clientY;

        if (this.props.filterProperty.name === this.props.activeDropDownFilterName) {
            this.scrollLock.unlock();
            // Empty string means no selected dropdown
            this.props.activeDropDownFilterChange('');
        } else {
            // delay is needed only for tablets.
            // for screen auto scroll, tablet only:
            if (isTablet || isIPad13)
                setTimeout(() => {
                    this.scrollLock.lock();
                }, 500);

            this.props.activeDropDownFilterChange(this.props.filterProperty.name);
        }
    }
}
