// placing it in article-tile.d.ts does not work because d.ts files are special 
// https://stackoverflow.com/questions/38335668/how-to-refer-to-typescript-enum-in-d-ts-file-when-using-amd
export enum ViewType {
    Category = 'Category',
    Search = 'Search',
    TeaserSuggestion = 'TeaserSuggestion'
}

export enum SortContextActionType {
    SetSortSelected = 'SetSortSelected',
    SetGloveRating = 'SetGloveRating',
    ResetRegularSortValues = 'ResetRegularSortValues',
    ResetGloveSortValues = 'ResetGloveSortValues'
}

export enum ArticleTileContextActionType {
    SetMspOrigin = 'SetMspOrigin'
}

export enum MatchEyecatcherType {
    percentage = 1,
    pro,
    contra,
} 
