export default class TabNavigationHelper {
    private static _instance: TabNavigationHelper;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public setGroupAtt(el: HTMLDivElement | HTMLAnchorElement): void {
        if (el) {
            if (!el.hasAttribute('group')) {
                el.toggleAttribute('group');
            }
        }
    }

    public setFocusAtt(el: HTMLDivElement | HTMLSpanElement): void {
        if(el) {
            el.setAttribute('focusable', '');
            el.setAttribute('order', '');
        }
    }

    public removeFocusAtt(el: HTMLDivElement | HTMLSpanElement | Element): void {
        if(el) {
            el.removeAttribute('focusable');
            el.removeAttribute('order');
            el.removeAttribute('tabindex');
        }
    }

    public removeGroupAtt(el: HTMLDivElement | HTMLSpanElement): void {
        if(el) {
            el.removeAttribute('group');
            el.removeAttribute('Modal');
        }
    }

    public setModalAtt(el: HTMLDivElement | HTMLAnchorElement): void {
        if(el) {
            el.setAttribute('Modal', '');
        }
    }

    public handleKeyDown(event, originElement?: string): void {
        event.stopPropagation(); // avoid triggering parent:

        // prevent page jump down on Space btn., click
        if (event.key === ' ') //'Space'
            event.preventDefault();

        if(originElement && originElement.length > 0) {
            const element = document.querySelector(`.${originElement}`);
            setTimeout(() => {window.shell.tabNav.focus(element);}, 500);
        }
    }
}
