import React from 'react';
import { SortContext } from './sortContextProvider';
import { ArticleTileContext } from './articleTileContext';

export const MultipleContext = React.createContext({ context1: {}, context2: {} });

export default function MultipleContextProvider(props: React.PropsWithChildren<{}>) {
    return (
        <SortContext.Consumer>
            {context1 => (
                <ArticleTileContext.Consumer>
                    {context2 => (
                        <MultipleContext.Provider value={{ context1, context2 }}>
                            {props.children}
                        </MultipleContext.Provider>
                    )}
                </ArticleTileContext.Consumer>
            )}
        </SortContext.Consumer>
    );
}

