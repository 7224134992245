/* eslint-disable max-len */
import { Component } from 'react';
import React from 'react';
import styles from './playPauseBtn.scss';
import classNames from 'classnames';
import TabNavigationHelper from '../../../../../Common/tabNavigationHelper';

export default class PlayPauseBtn extends Component<{slideShowPaused: boolean,
    imageAmount: number, on_hover: boolean, slideIndex: number
    sdvColorColorCode: number, tileId: string}, {useRegularSlideDuration: boolean}> {
    private circleMask = 'cm_' + this.props.tileId;
    private circle_1_id = 'c1_' + this.props.tileId;
    private circle_2_id = 'c2_' + this.props.tileId;
    private circleAnimationTimer: ReturnType<typeof setTimeout>;
    private circleAnimationTimer_play: ReturnType<typeof setTimeout>;
    private resetOnMouseLeaveTimer: ReturnType<typeof setTimeout>;
    private readonly ref_circle_2 = React.createRef<SVGCircleElement>();
    private readonly defaultPathLength = 1000;
    private readonly filledStrokes = 1000;
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;
        this.state = {
            useRegularSlideDuration: false
        };
    }
    
    componentDidUpdate(prevProps): void {
        if(!this.ref_circle_2.current)
            return;

        if(prevProps.sdvColorColorCode !== this.props.sdvColorColorCode)
        {
            this.setState({
                useRegularSlideDuration: true
            }, () => {this.resetLoadingStrokeDasharray();});
        }
        else if (prevProps.slideShowPaused !== this.props.slideShowPaused) {
            if (this.props.slideShowPaused) {
                if(this.props.slideIndex === 1 && this.props.slideIndex < prevProps.slideIndex) {
                    // animation was finished:
                    this.resetLoadingStrokeDasharray();
                }
                else {
                    // animation was paused:
                    this.ref_circle_2.current.classList.remove(styles.lets_play_a_game);
                }
            }
            else {                
                clearTimeout(this.circleAnimationTimer);
                clearTimeout(this.circleAnimationTimer_play);
                clearTimeout(this.resetOnMouseLeaveTimer);

                this.circleAnimationTimer = setTimeout(() => {
                    this.ref_circle_2.current.classList.add(styles.circle_progress);
                }, 15);

                this.circleAnimationTimer_play = setTimeout(() => {
                    this.ref_circle_2.current.classList.add(styles.lets_play_a_game);
                }, 25);
            }
        }
        
        // hover ended, reset play btn state, and avoid inner endless state change loop
        if(!this.props.on_hover && (prevProps.on_hover !== this.props.on_hover)) {
            this.resetOnMouseLeaveTimer = setTimeout(() => {
                this.setState({
                    useRegularSlideDuration: false
                }, () => {this.resetLoadingStrokeDasharray();});
            }, 700);
        }

        // Update animation timer on second slide and same article variant.
        // It is needed because first slide takes only 0.75 second.
        if(this.props.slideIndex === 2 && prevProps.sdvColorColorCode === this.props.sdvColorColorCode 
            && this.props.on_hover) {
                this.setAnimationDuration();
            }
    }

    componentDidMount(): void {
        if(this.ref_circle_2.current) {
            this.setAnimationDuration();
        }
    }

    componentWillUnmount(): void {
        // clear previous timeout:
        clearTimeout(this.circleAnimationTimer);
        clearTimeout(this.circleAnimationTimer_play);
        clearTimeout(this.resetOnMouseLeaveTimer);
    }

    private setAnimationDuration(): void {
        // default values:
        const regularSlideDuration = 3; // time in seconds
        const firstSlideDuration =  0.75; // time in seconds
        let animationDuration = 4;
        let filledStrokes = this.filledStrokes;

        // About useRegularSlideDuration ->
        // changes first slide duration to regular, because only main article needs short main image animation:

        // first slide loader goes fast and just for that single gap
        if(this.props.slideIndex === 1 && !this.state.useRegularSlideDuration) {
            animationDuration = firstSlideDuration;
            filledStrokes = this.defaultPathLength / this.props.imageAmount;
        }
        else {
            const firsSlideNumber = this.state.useRegularSlideDuration ? 0 : 1;
            // continue rest of the animation normally:
            animationDuration = (this.props.imageAmount - firsSlideNumber) * regularSlideDuration;
            let transparencyFraction = 0;

            if(!this.state.useRegularSlideDuration) {
                // value for smooth start after first slide:
                switch (true) {
                    case (this.props.imageAmount <= 2): {
                        transparencyFraction = 150;
                        break;
                    }
                    case (this.props.imageAmount <= 4): {
                        transparencyFraction = 100;
                        break;
                    }
                    case (this.props.imageAmount <= 8): {
                        transparencyFraction = 50;
                        break;
                    }
                    case (this.props.imageAmount <= 10): {
                        transparencyFraction = 25;
                        break;
                    }
                }

                this.ref_circle_2.current.style.setProperty('--startingPoint', 
                ((this.defaultPathLength / this.props.imageAmount) - transparencyFraction).toString());
            }
        }

        this.ref_circle_2.current.style.setProperty('--filledStrokes', 
            filledStrokes.toString());
        
        this.ref_circle_2.current.style.setProperty('--animationDuration', 
            animationDuration.toString()+'s');
    }

    private resetLoadingStrokeDasharray(): void {
        if (!this.ref_circle_2.current)
            return;

        this.setAnimationDuration();
        this.ref_circle_2.current.classList.remove(styles.lets_play_a_game);
        this.ref_circle_2.current.classList.remove(styles.circle_progress);

        this.circleAnimationTimer = setTimeout(() => {
            this.ref_circle_2.current.classList.add(styles.circle_progress);
        }, 15);

        this.ref_circle_2.current.style.setProperty('--startingPoint', '0');
    }

    private getStrokeDasharray(): string {
        return `${(this.defaultPathLength - this.props.imageAmount * 30)/this.props.imageAmount} 30`;
    }

    private handleKeyDown(event): void {
        // prevent events on tile core:
        event.stopPropagation();   
    }

    public render() {
        return (
            <div className={classNames(styles.play_pause_btn,
            {[styles.show_btn]: this.props.on_hover})} data-testid='play_pause_btn'
            ref={(el)=>this.tabNav.setFocusAtt(el)}
            onKeyDown={this.handleKeyDown}>
                <svg viewBox="0 0 100 100" width="50" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <mask id={this.circleMask}>
                            <circle cx="50" cy="50" r="40"
                                fill="none"
                                stroke="white"
                                strokeWidth="7"
                                strokeDasharray={this.getStrokeDasharray()}
                                pathLength={this.defaultPathLength} />
                        </mask>
                    </defs>
                    <g transform="rotate(-85 50 50)">
                        <circle mask={'url(#' + this.circleMask + ')'}
                            id={this.circle_1_id}
                            cx="50" cy="50" r="40"
                            className={styles.base_circle}
                            pathLength={this.defaultPathLength}
                        />
                        <circle mask={'url(#' + this.circleMask + ')'}
                            id={this.circle_2_id}
                            ref={this.ref_circle_2}
                            cx="50" cy="50" r="40"
                            className={classNames(styles.cp_base, styles.circle_progress)}
                            pathLength={this.defaultPathLength}
                            data-testid='ref_circle_2'
                        >
                        </circle>
                    </g>
                    <circle cx="50" cy="50" r="30" dominantBaseline="middle" />
                    {this.props.slideShowPaused ?
                        <g>
                            <path fill="white"
                                d="M 43.683 63.948 C 41.646 63.947 39.995 62.39 39.994 60.468 L 39.994 40.073 C 39.995 37.394 43.07 35.72 45.529 37.06 C 45.571 37.084 45.614 37.107 45.655 37.133 L 62.714 47.328 C 65.004 48.695 65.003 51.846 62.713 53.21 L 45.655 63.408 C 45.064 63.76 44.381 63.948 43.683 63.948 Z M 43.684 39.885 C 43.65 39.887 43.617 39.895 43.587 39.911 C 43.523 39.942 43.484 40.006 43.488 40.073 L 43.488 60.468 C 43.484 60.536 43.523 60.599 43.587 60.63 C 43.649 60.669 43.73 60.665 43.787 60.623 L 60.847 50.425 L 60.847 50.114 L 43.787 39.918 C 43.757 39.897 43.721 39.887 43.684 39.885 Z" />
                        </g> :
                        <g transform="matrix(1.520921, 0, 0, 1.466983, 23.352144, 23.199921)">
                            <path fill="white" d="M14.00013,24.76385a.90065.90065,0,0,1-.90039-.90039V11.13592a.90039.90039,0,0,1,1.80078,0V23.86346A.90065.90065,0,0,1,14.00013,24.76385Z" width="5">
                            </path>
                            <path fill="white" width="3" d="M21.00013,24.76385a.90065.90065,0,0,1-.90039-.90039V11.13592a.90039.90039,0,1,1,1.80078,0V23.86346A.90065.90065,0,0,1,21.00013,24.76385Z">
                            </path>
                        </g>
                    }
                </svg>
            </div>
        );
    }
}
