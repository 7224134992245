import React from 'react';
import sortContextReducer, {initState} from './sortContextReducer';
import { SortType, ISortProperty } from '../sort-side-content/sort-side-content.d';
import { ISortContextProviderState, ISortContextProviderProps, ISortContext } from './sortContextProvider.d';
import { IFilterPropertyValue } from '../fas-bar/filter-property-value.d';
import { SortContextActionType } from '../../Common/enums';
import { UrlController } from './UrlController';

export const SortContext = React.createContext<ISortContext>(null);

function SortContextProvider(props: React.PropsWithChildren<ISortContextProviderProps>) {
    const { viewType, l10n, glovesFilter, initialSort, isMobile, magicLink } = props;
    const [state, dispatch] = React.useReducer(sortContextReducer,
        {l10n, viewType, glovesFilter, initialSortUrlParam: initialSort}, initState);
    
    const onChangeSideEffects = React.useRef([]);
    const urlController = React.useMemo(() => new UrlController(null, viewType, magicLink),[]);

    React.useEffect(() => {
        if (!isMobile) {
            updateUrlParams(state);
        }
    });

    const updateUrlParams = React.useCallback((contextState: ISortContextProviderState) => {
        const selectedSort = contextState.sortPropertyList.find(item => item.selected);
        if (selectedSort) {
            urlController.updateSortUrl(selectedSort.urlValue);
        }

        const hasGloveSortProps = contextState.glovesSortPropValues.length > 0;
        if (hasGloveSortProps) {
            let gloveSortUrlValue = '';

            // Collect ratings:
            contextState.glovesSortPropValues.forEach((gsProperty) => {
                if (gsProperty.glovesRate > 0) {
                    if (gloveSortUrlValue.length === 0)
                        gloveSortUrlValue = gsProperty.slug.concat(':', gsProperty.glovesRate.toString());
                    else
                        gloveSortUrlValue = gloveSortUrlValue.concat(',',
                            gsProperty.slug, ':', gsProperty.glovesRate.toString());
                }
            });

            urlController.updateGloveSortUrl(contextState.sortPropertyList, gloveSortUrlValue);
        }
    }, []);

    const applyContextChanges = React.useCallback((
        selectedSort?: ISortProperty,
        updateUrl = false,
        updateArticleList = true,
        applySideEffects = true,
        newState?: ISortContextProviderState,
    ) => {
        if (updateUrl) {
            updateUrlParams(newState || state);
        }

        if (updateArticleList) {
            props.updateArticleList(false);
        }

        if (applySideEffects) {
            useSideEffects();
        }
    }, [state]);

    const addOnChangeSideEffect = React.useCallback((cb: Function) => {
        onChangeSideEffects.current.push(cb);
    }, []);

    const removeOnChangeSideEffect = React.useCallback((cb: Function) => {
        const index = onChangeSideEffects.current.findIndex(item => item === cb);
        if (index > -1) {
            onChangeSideEffects.current.splice(index, 1);
        }
    }, []);

    const useSideEffects = React.useCallback(() => {
        onChangeSideEffects.current.forEach(cb => typeof cb === 'function' && cb());
    }, []);

    const activeGlovesSortPropValues = React.useMemo((): IFilterPropertyValue[] => {
        return state.glovesSortPropValues.filter((prop) => prop.glovesRate > 0);
    }, [state.glovesSortPropValues]);

    const setSortSelected = React.useCallback((selectedSort: ISortProperty, updateUrl = false,
                                               updateArticleList = true, applySideEffects = true) => {
        dispatch({type: SortContextActionType.SetSortSelected, sortType: selectedSort.sortType});
        const newState = sortContextReducer(state, {type: SortContextActionType.SetSortSelected,
            sortType: selectedSort.sortType});
        applyContextChanges(selectedSort, updateUrl, updateArticleList, applySideEffects, newState);
    }, [state.sortPropertyList, state.glovesSortPropValues]);

    const setGloveRating = React.useCallback((
        gloveProp: IFilterPropertyValue,
        newRating: number,
        updateUrl = false,
        updateArticleList = true,
        applySideEffects = true
    ) => {
        dispatch({type: SortContextActionType.SetGloveRating, gloveSlug: gloveProp.slug, gloveRating: newRating});

        // force to update active articles grid:
        const newState = sortContextReducer(state, 
            {type: SortContextActionType.SetGloveRating, 
                gloveSlug: gloveProp.slug, 
                gloveRating: newRating
            });

        applyContextChanges(null, updateUrl, updateArticleList, applySideEffects, newState);
    }, [state.sortPropertyList, state.glovesSortPropValues]);

    const removeRegularSortValues = React.useCallback((
        updateUrl = false,
        updateArticleList = true,
        applySideEffects = true
    ) => {
        dispatch({type: SortContextActionType.ResetRegularSortValues});
        const popSort = state.sortPropertyList.find(item => item.sortType === SortType.Popularity);
        applyContextChanges(popSort, updateUrl, updateArticleList, applySideEffects);
    }, [state.sortPropertyList, state.glovesSortPropValues]);

    const removeAllGloveSortValues = React.useCallback((
        updateUrl = true,
        updateArticleList = true,
        applySideEffects = true
    ) => {
        dispatch({type: SortContextActionType.ResetGloveSortValues});
        const newState = sortContextReducer(state, {type: SortContextActionType.ResetGloveSortValues});
        applyContextChanges(null, updateUrl, updateArticleList, applySideEffects, newState);
    }, [state.sortPropertyList, state.glovesSortPropValues]);

    const providerValue: ISortContext = {
        ...state, setSortSelected, setGloveRating, removeRegularSortValues, removeAllGloveSortValues,
        applyContextChanges, addOnChangeSideEffect, removeOnChangeSideEffect, activeGlovesSortPropValues
    };

    return (
        <SortContext.Provider value={providerValue}>
            {props.children}
        </SortContext.Provider>
    );
}

export default SortContextProvider;
