import * as React from 'react';
import styles from './availableSizesBtn.scss';
import ArrowDownIcn from '../../../../Assets/svg/arrow_down';
import * as helper from '../../../../Common/html-helper';
import TabNavigationHelper from '../../../../Common/tabNavigationHelper';

export default class AvailableSizesBtn extends React.Component<{
    toggleAvailableSizes(): void;
    btnText: string;

}, {}> {
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;
    }

    private handleKeyDown(event): void {
        // prevent events on tile core:
        event.stopPropagation();   
    }

    public render() {
        const btn_text = helper.decodeHTML(this.props.btnText);
        return (
            <div ref={(el) => this.tabNav.setFocusAtt(el)}
                onKeyDown={this.handleKeyDown}
                className={styles.available_sizes_btn}
                data-testid='available_sizes_btn'
                onClick={this.props.toggleAvailableSizes}
                onTouchEnd={this.props.toggleAvailableSizes}
                title={btn_text}
            >
                {btn_text}
                <ArrowDownIcn />
            </div>
        );
    }
}
