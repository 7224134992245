import React, { Component, MouseEvent } from 'react';
import styles from './availableSizes.scss';
import Cross from '../../../../Assets/svg/cross';
import ArrowDownIcn from '../../../../Assets/svg/arrow_down';
import classNames from 'classnames';
import { ISalesArticleVariantsLookup, ISdvColor, ISize } from '../article-tile.d';
import TabNavigationHelper from '../../../../Common/tabNavigationHelper';
import { ArticleTileContext } from '../../../globalState/articleTileContext';

export default class AvailableSizes extends Component<{
    salesArticleVariantColors: ISdvColor[],
    showAvailableSizesMenu: boolean,
    dcvColorCode: number,
    toggleAvailableSizes(): void,
    sizes: ISize[]
    salesArticleVariantsLookup: ISalesArticleVariantsLookup[],
    masterArticleNo?: number
    }, 
    {needLeftRightNavigation: boolean, verticalScrollSteps: number, 
    currentVSStep: number,
    articleVariantColors: ISdvColor[]}>{
    static contextType = ArticleTileContext;
    
    private readonly upperSectionRef = React.createRef<HTMLDivElement>();
    private readonly centralSectionRef = React.createRef<HTMLDivElement>();
    private readonly colorsSectionRef = React.createRef<HTMLDivElement>();
    private readonly asContainerRef = React.createRef<HTMLDivElement>();
    private readonly rowPerStep = 3;
    private readonly scrollStep = 52 * this.rowPerStep; // 3 x sizeValue
    private tabNav: TabNavigationHelper;

    constructor(props) {
        super(props);
        this.tabNav = TabNavigationHelper.instance;

        this.state = {
            needLeftRightNavigation: false,
            verticalScrollSteps: 0,
            currentVSStep: 1,
            articleVariantColors: []
        };
        
        this.handleUpDownClick = this.handleUpDownClick.bind(this);
        this.handleLeftRightClick = this.handleLeftRightClick.bind(this);
    }

    componentDidMount(): void {
        this.getSaleTypeArticlesVariantColors();
    }

    componentDidUpdate(prevProps): void {
        if((this.props.showAvailableSizesMenu !== prevProps.showAvailableSizesMenu) 
            && this.centralSectionRef.current
            && this.upperSectionRef.current) {
            // sizes slot width width,
            // general parent container - colors colum:
            if(this.centralSectionRef.current.scrollWidth 
                > (this.upperSectionRef.current.clientWidth - 55))
                this.setState({needLeftRightNavigation: true});
            else
                this.setState({needLeftRightNavigation: false});
        }

        // for mobile devices, when single component is used with different articles
        if(prevProps.masterArticleNo !== this.props.masterArticleNo) {
            this.getSaleTypeArticlesVariantColors();
        }

        if(this.asContainerRef.current) {
            this.props.showAvailableSizesMenu === true ? 
            this.asContainerRef.current.removeAttribute('disabled'):
            this.asContainerRef.current.setAttribute('disabled', '');
        }
    }

    private getIconSrc(colorCode) {
        return `${this.context.l10n.cdnUrl}assets/ats/colors64px/Original/${colorCode}.png`
    }

    private handleLeftRightClick(left: boolean, e?: MouseEvent): void {
        if (e) {
            e.preventDefault(); //the ArticleTile has a <a>-tag to link to PDP, we want to avoid navigation here
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.stopPropagation();

            if(this.centralSectionRef && this.centralSectionRef.current) {
                if(left)
                    this.centralSectionRef.current.scrollLeft -= this.scrollStep;
                else
                    this.centralSectionRef.current.scrollLeft += this.scrollStep;
            }
        }
    }

    private handleUpDownClick(up: boolean, e?: MouseEvent): void {
        if (e) {
            e.preventDefault(); //the ArticleTile has a <a>-tag to link to PDP, we want to avoid navigation here
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.stopPropagation();
        }        

        if (this.centralSectionRef && this.centralSectionRef.current
            && this.colorsSectionRef && this.colorsSectionRef.current) {
            let extraScrollStep = 0;
            const verticalScrollStepsNotEqual = this.state.articleVariantColors.length / this.rowPerStep;

            if (up) {
                if (this.state.currentVSStep === this.state.verticalScrollSteps - 1) {                    
                    // before last step, check scroll. We have to move till the end of the container
                    verticalScrollStepsNotEqual ? extraScrollStep = 5 : extraScrollStep = 0;
                }

                this.centralSectionRef.current.scrollTop += this.scrollStep + extraScrollStep;
                this.colorsSectionRef.current.scrollTop += this.scrollStep + extraScrollStep;

                if(this.state.currentVSStep + 1 !== this.state.verticalScrollSteps)
                    this.setState({ currentVSStep: this.state.currentVSStep + 1 });
            }
            else {
                if (this.state.currentVSStep === 1) {
                    verticalScrollStepsNotEqual ? extraScrollStep = -5 : extraScrollStep = 0;
                }

                this.centralSectionRef.current.scrollTop -= this.scrollStep - extraScrollStep;
                this.colorsSectionRef.current.scrollTop -= this.scrollStep - extraScrollStep;

                if(this.state.currentVSStep !== 1)
                    this.setState({ currentVSStep: this.state.currentVSStep - 1 });
            }
        }
    }

    private getSaleTypeArticlesVariantColors(): void {
        const tmpSalesAVC: ISdvColor[] = this.props.salesArticleVariantColors.filter(it=>it.isSale === true);

        if(tmpSalesAVC && tmpSalesAVC.length > 0) {
            this.setState({
                articleVariantColors: tmpSalesAVC,
                verticalScrollSteps: Math.floor(tmpSalesAVC.length / this.rowPerStep),
            });
        }
        else {
            this.setState({
                articleVariantColors: [],
                verticalScrollSteps: 0,
            });
        }
    }

    private checkAvailability(sizeCode: number, colorCode: number): boolean {
        const existingVariants = this.props.salesArticleVariantsLookup
            .filter(it=>it.sizeCode === sizeCode && it.colorCode === colorCode);

        return existingVariants && existingVariants.length > 0;
    }

    private appendPdpLink(sizeCode: number, pdpLink: string, salesArticleNo: string): string {
        if(this.state.articleVariantColors.length > 1)
            return pdpLink.includes('?') ? pdpLink + '&size=' + sizeCode : pdpLink + '?size=' + sizeCode;

        // some times salesArticleNo is not the same for all sizes.
        // It might be different per size in single color.
        // in this case we must update pdp link, because it is made by default color variant.

        const filteredSavBySize = this.props.salesArticleVariantsLookup.find(it=> it.sizeCode === sizeCode);

        if(filteredSavBySize && filteredSavBySize.salesArticleNo !== salesArticleNo) {
            const newPdpLink = pdpLink.replace(salesArticleNo, filteredSavBySize.salesArticleNo);
            return newPdpLink.includes('?') ? newPdpLink + '&size=' + sizeCode : newPdpLink + '?size=' + sizeCode;
        }
        else {
            // fall back just in case:
            return pdpLink.includes('?') ? pdpLink + '&size=' + sizeCode : pdpLink + '?size=' + sizeCode;
        }
    }

    private handleKeyDown(event): void {
        // prevent events on tile core:
        event.stopPropagation();   
    }

    public render() {
        return (
            <div data-testid='available_sizes_container' ref={this.asContainerRef}
            className={classNames(styles.available_sizes_container,
            {[styles.show_as]: this.props.showAvailableSizesMenu}, 
            {[styles.one_color_only]: this.state.articleVariantColors.length === 1})}>
                <div className={styles.upper_section} ref={this.upperSectionRef}>
                    <div className={styles.colors_section} ref={this.colorsSectionRef}>
                        {this.state.articleVariantColors.map((sav, index) => {
                            return (<div key={index} className={classNames(styles.color_item,
                                { [styles.on_hover]: this.props.dcvColorCode === sav.color.code }
                            )}>
                                <img className={styles.color_icon}
                                    loading='lazy'
                                    src={this.getIconSrc(sav.color.code)}
                                    title={sav.color.name} />
                            </div>)
                        })}
                    </div>
                    <div className={styles.central_section} ref={this.centralSectionRef}>
                        {
                            this.state.articleVariantColors.map((sav, index) => {
                                return (<div className={styles.sizes_chunk} 
                                key={index} 
                                ref={(el) => this.tabNav.setGroupAtt(el)}>
                                    {this.props.sizes.map((sizeValue, index) => {
                                        const sizeAvailable = this.checkAvailability(sizeValue.code,
                                            sav.color.code);
                                        return sizeAvailable === true ? 
                                        (<a className={styles.size_value} key={index} 
                                            data-size-code={sizeValue.code}
                                            ref={(el) => this.tabNav.setFocusAtt(el)}
                                            href={this.appendPdpLink(sizeValue.code, sav.pdpLink, 
                                                sav.salesArticleNo)} >
                                            <span className={styles.size_label}>{sizeValue.name}</span>
                                        </a>) : <span className={classNames(styles.size_value, styles.s_not_available)} 
                                            key={index} data-size-code={sizeValue.code}>
                                            <span className={styles.size_label}>{sizeValue.name}</span>
                                        </span>;
                                    })}
                                </div>)
                            })
                        }
                    </div>
                    {this.props.toggleAvailableSizes !== null &&
                        <div className={classNames(styles.right_section,
                            { [styles.need_left_right_navigation]: this.state.needLeftRightNavigation })}
                            ref={(el) => this.tabNav.setGroupAtt(el)}>
                            <div className={classNames(styles.arrow_down, styles.arrow_left)}
                                ref={(el) => this.tabNav.setFocusAtt(el)}
                                data-testid='arrow_left'
                                onClick={(e) => { this.handleLeftRightClick(true, e) }}>
                                <ArrowDownIcn />
                            </div>
                            <div className={classNames(styles.arrow_down, styles.arrow_right)}
                                ref={(el) => this.tabNav.setFocusAtt(el)}
                                data-testid='arrow_right'
                                onClick={(e) => { this.handleLeftRightClick(false, e) }}>
                                <ArrowDownIcn />
                            </div>
                        </ div>
                    }
                </div>
                {this.props.toggleAvailableSizes !== null &&
                    <div className={classNames(styles.bottom_section,
                        { [styles.close_button_right]: this.state.articleVariantColors.length <= 4 })}
                        ref={(el) => this.tabNav.setGroupAtt(el)}>
                        <div className={classNames(styles.center_bottom_section,
                            { [styles.no_ap_down_navigation]: this.state.articleVariantColors.length <= 4 })}>
                            <div className={styles.arrow_down}
                                ref={(el) => this.tabNav.setFocusAtt(el)}
                                data-testid='arrow_down'
                                onClick={(e) => { this.handleUpDownClick(true, e) }}>
                                <ArrowDownIcn />
                            </div>
                            <div className={styles.arrow_up}
                                ref={(el) => this.tabNav.setFocusAtt(el)}
                                data-testid='arrow_up'
                                onClick={(e) => { this.handleUpDownClick(false, e) }}>
                                <ArrowDownIcn />
                            </div>
                        </div>
                        <div className={styles.close_button}
                            ref={(el) => this.tabNav.setFocusAtt(el)}
                            data-testid='close_button'
                            onClick={this.props.toggleAvailableSizes}
                            onKeyDown={this.handleKeyDown}>
                            <Cross />
                        </div>
                    </ div>
                }
            </div>
        );
    }
}
