import * as React from 'react';
import * as helper from '../../Common/html-helper';
import classNames from 'classnames';
import styles from './tooltipComponent.scss';
import { ITooltipComponent, ITooltipComponentState } from './tooltipComponent.d';
import TabNavigationHelper from '../../Common/tabNavigationHelper';

export default class TooltipComponent extends React.Component<ITooltipComponent, ITooltipComponentState> {
    private tabNav: TabNavigationHelper;
    private closeButtonRef = React.createRef<HTMLButtonElement>();
    private previouslySelectedElement: Element;

    constructor(props: ITooltipComponent) {
        super(props);
        this.state = {};
        this.tabNav = TabNavigationHelper.instance;

        this.closeButtonClicked = this.closeButtonClicked.bind(this);
    }

    public componentDidMount() {
        if(this.closeButtonRef.current) {
            this.tabNav.setFocusAtt(this.closeButtonRef.current);
        }
    }

    componentDidUpdate(): void {
        if(this.props.showTooltip) {
            this.previouslySelectedElement = document.activeElement;
            window.shell.tabNav.focus(this.closeButtonRef.current);
        }
    }

    public render() {
        return (
            <div className={classNames(styles.tooltip_overlay, this.props.showTooltip ? styles.show : '')} 
                ref={(el)=> {this.tabNav.setGroupAtt(el); this.tabNav.setModalAtt(el)}}>
                <div className={classNames(styles.modal_box)}>
                    <button className={classNames(styles.close_button)}
                        data-testid='close_button'
                        onClick={this.closeButtonClicked}
                        ref={this.closeButtonRef}></button>
                    {!this.props.imageName ? null : <div className={classNames(styles.tooltip_image,
                        this.props.imageName)} />}
                    <div className={styles.tooltip_text}>{this.toolTipText()}</div></div>
            </div>
        );
    }

    private closeButtonClicked(event: React.MouseEvent) {
        window.shell.tabNav.focus(this.previouslySelectedElement);
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(false, this.props.text, this.props.imageName);
    }

    private toolTipText(): string {
        return this.props.text ? helper.decodeHTML(this.props.text) : '';
    }
}
